$ENV: production;
$spinnerSize: 32px;
$spinnerColor: theme('colors.arcticgray.900');

@import 'tailwindcss/base';

@import 'base/base';
@import 'base/animation';
@import 'base/fonts';

@import 'tailwindcss/components';

@import 'components/banner';
@import 'components/graph';
@import 'components/event-timeline';
@import 'components/whitepaper';
@import 'components/marquee-testimonials';

@import 'tailwindcss/utilities';

@layer utilities {
  .grid-gutters-x-2 > div:nth-child(2),
  .grid-gutters-x-3 > div:nth-child(2),
  .grid-gutters-x-3 > div:nth-child(3) {
    position: relative;

    &::after {
      content: '';
      display: block;
      height: 100%;
      width: 1px;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(-1.25rem);
      background-color: theme('colors.arcticgray.300');
    }
  }

  .grid-gutters-x-0 > div::before {
    content: none;
  }

  .grid-gutters-y > div:not(:last-child) {
    position: relative;

    &::before {
      content: '';
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(1.25rem);
      background-color: theme('colors.arcticgray.300');
    }
  }

  .grid-gutters-y-0 > div:not(:last-child)::before {
    content: none;
  }
}

[x-cloak] { display: none !important; }

.prose thead th {
  vertical-align: top;
}

// GENERAL BLOCK SPACING

// Apply default top margin to all blocks that are preceded by another block
[class*="cs-block-"] + [class*="cs-block-"] {
  @apply mt-10 md:mt-20;
}

// Add margin between last block and page-footer
[class*="cs-block"]:last-child:not([class*="bg-"]) {
  @apply mb-10 md:mb-20;
}

// Always remove margin, if a block with backgrounds of some sort
// is preceded by another block with a background
[class*="cs-block-"][class*="bg-"] + [class*="cs-block-"][class*="bg-"]  {
  @apply mt-0 md:mt-0;
}

// SPECIFIC BLOCK SPACING

// .cs-block-testimonials {
//   @apply mt-0 md:mt-0;
// }

.cs-block-feature-grid-large {
  @apply mt-0 md:mt-0;
}

.cs-block-feature-grid-large + .cs-block-cs_video {
  @apply mt-0 md:mt-0;
}

.cs-block-how-cybersense-works + .cs-block-testimonials {
  @apply mt-0 md:-mt-32 pt-16;
}

[class*="cs-block-"] + .cs-block-text-image-large {
  @apply mt-32 md:mt-32;
}

[class*="cs-block-"] + .cs-block-contact {
  @apply mt-32 md:mt-32;
}
