.cs-block-whitepaper__image {
  background-color: #d3805f;
  overflow: hidden;
  
  img {
    box-shadow: -4px 4px 4px 0 rgba(0,0,0,0.15), -8px 8px 8px 0 rgba(0,0,0,0.50);
    max-width: 15rem;
  }
}


@media screen and (max-width: calc(theme('screens.lg') - 1px)) {
  .cs-block-whitepaper {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }

  .cs-block-whitepaper__image {
    display: flex;
    justify-content: center;
    height: 20rem;
    border-radius: 0.25rem;

    img {
      transform: translateY(3rem);
    }
  }
}


@media screen and (min-width: theme('screens.xs')) {
  .cs-block-whitepaper {
    max-width: theme('screens.xs');
  }
}

@media screen and (min-width: theme('screens.sm')) {
  .cs-block-whitepaper {
    max-width: theme('screens.sm');
  }
}

@media screen and (min-width: theme('screens.md')) {
  .cs-block-whitepaper {
    max-width: theme('screens.md');
  }
}



@media screen and (min-width: theme('screens.lg')) {
  .cs-block-whitepaper {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: calc((100vw - theme('screens.lg')) / 2 + 20px);
    margin-right: 0;
    width: calc(100vw - ((100vw - theme('screens.lg')) / 2) - 20px);

    .cs-block-whitepaper__text {
      width: calc(theme('screens.lg') / 12 * 7 - 40px);
      flex-shrink: 0;
    }

    .cs-block-whitepaper__image {
      margin-left: 40px;
      padding: 4rem calc((100vw - theme('screens.lg')) / 2 + 20px + 5.5rem) 4rem 5.5rem;
      border-radius: 0.25rem 0 0 0.25rem;

    }
  }
}


@media screen and (min-width: theme('screens.xl')) {
  .cs-block-whitepaper {
    max-width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: calc((100vw - theme('screens.xl')) / 2 + 20px);
    margin-right: 0;
    width: calc(100vw - ((100vw - theme('screens.xl')) / 2) - 20px);

    .cs-block-whitepaper__text {
      width: calc(theme('screens.xl') / 12 * 7 - 40px);
      flex-shrink: 0;
    }

    .cs-block-whitepaper__image {
      margin-left: calc(theme('screens.xl') / 12);
      padding: 4rem calc((100vw - theme('screens.xl')) / 2 + 20px + 5.5rem) 4rem 5.5rem;
    }
  }
}

@media screen and (min-width: 1536px) {
  .cs-block-whitepaper {
    .cs-block-whitepaper__image {
      width: calc(theme('screens.xl') / 3);
      margin-right: calc((100vw - theme('screens.xl')) / 2 + 20px);
      padding: 4rem 5.5rem;
      border-radius: 0.25rem;
    }
  }
}