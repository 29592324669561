#event-timeline-table {
  position: absolute;
  top: 300px;
  left: 1rem; // Actually 1.25rem, but this way we overlap the dashed line of the timeline a fair bit

  @media screen and (min-width: 1024px) {
    top: 300px;
    left: 340px;
  }
}

@media screen and (max-width: 639px) {
.event-timeline:after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 5rem;
    content: '';
    background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(#F2F7FC,0.9) 100%);
  }
}
