*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

::selection {
  color: theme('colors.arcticgray.100');
  background: theme('colors.arcticgray.800');
}

html {
    font-size: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
    touch-action: manipulation;
}

body {
    box-sizing: border-box;
}

html, body {
  text-rendering: geometricPrecision;
}

.off-screen {
  position: absolute;
  top: -9999px;
  left: -9999px;
}

nav.main > ul > li > ul::before {
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  position: absolute;
  top: -0.25rem;
  left: calc(50% - 1rem);
  z-index: 0;
  transform: rotate(-45deg);
  background-color: theme('colors.arcticgray.50');
}


.product-cta {
  .circle-gradient {
    display: block;
    content: '';
    width: 40rem;
    height: 40rem;
    background: linear-gradient(to right, theme('colors.arcticgray.100'), theme('colors.arcticgray.500'));
    opacity: .1;
    border-radius: 100%;
  }
  .circle-gradient-1 {
    position: absolute;
    right: -22rem;
    top: 35rem;
  }
  .circle-gradient-2 {
    position: absolute;
    right: -25rem;
    bottom: -10rem;
  }
}

@media screen and (min-width: 641px) {
  .product-cta {
    .circle-gradient-1 {
      position: absolute;
      right: -9.5rem;
      top: 33rem;
    }
    .circle-gradient-2 {
      position: absolute;
      right: -20rem;
      bottom: -25rem;
    }
  }
}


@media screen and (min-width: 769px) {
  .product-cta {
    .circle-gradient {
      width: 50rem;
      height: 50rem;
    }
    .circle-gradient-1 {
      right: -7.5rem;
      top: 7rem;
    }
    .circle-gradient-2 {
      right: -17.5rem;
      bottom: -39rem;
    }
  }
}

.cta {

  .clip-image {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .clip-image {
    clip-path: circle(300px at 100% 50px);
  }

  @media screen and (min-width: 1024px) {
    .clip-image {
      width: 55%;
      clip-path: circle(500px at 100% 25%);
    }
    img {
    }
  }

  @media screen and (min-width: 1280px) {
    .clip-image {
      width: 65%;
      clip-path: circle(700px at 100% 25%);
    }
    img {
    }
  }
}


.caret {
  animation: blinking-caret 1s step-end infinite;
}

.spinner {
  width: $spinnerSize;
  height: $spinnerSize;
  x: 0px;
  y: 0px;
  viewBox: 0 0 $spinnerSize $spinnerSize;

  circle {
    fill: transparent;
    stroke: $spinnerColor;
    stroke-width: 4;
    stroke-linecap: round;
    stroke-dasharray: (3.14 * $spinnerSize);
    transform-origin: (0.5 * $spinnerSize) (0.5 * $spinnerSize) 0;
    animation: spinner 4s linear infinite;
  }
}

.lazyload,
.lazyloading,
.lazyloaded {
  transition: opacity .3s ease;
}

.lazyload,
.lazyloading {
  opacity: 0;
}

.lazyloaded {
  opacity: 1;
}

.button--arrow {
  position: relative;
}

.button--arrow :before {
  position: absolute;
  display: block;
  content: '';
  width: 2rem;
  height: 2rem;
  transform: translate(-2.95rem, -0.325rem);
  background: white;
  border-radius: 50%;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%23041029' stroke-width='2' fill='none' fill-rule='evenodd' stroke-linecap='square'%3E%3Cpath d='m14 11.5 5 5M14 21.5l5-5'/%3E%3C/g%3E%3C/svg%3E");
  opacity: 0;
  transition: opacity .2s ease;
}

.button--arrow:hover :before {
  opacity: 1;
  transition: opacity .4s .1s ease;
}
