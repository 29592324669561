
@font-face {
  font-family: 'TiemposHeadline-Semibold';
  src: url('..webfonts/tiempos-headline-web-semibold.eot'); /* IE9 Compat Modes */
  src: url('..webfonts/tiempos-headline-web-semibold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
          url('../webfonts/tiempos-headline-web-semibold.woff2') format('woff2'),
          url('../webfonts/tiempos-headline-web-semibold.woff') format('woff');
  font-style:   normal;
  font-weight:  700;
}



/*!
 * Web Fonts from Fontspring.com
 *
 * All OpenType features and all extended glyphs have been removed.
 * Fully installable fonts can be purchased at https://www.fontspring.com
 *
 * The fonts included in this stylesheet are subject to the End User License you purchased
 * from Fontspring. The fonts are protected under domestic and international trademark and 
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2010-2022 Fontspring
 *
 *
 *
 *
 * The fonts included are copyrighted by the vendor listed below.
 *
 * Vendor:      Adobe
 * License URL: https://www.fontspring.com/licenses/adobe/webfont
 *
 *
 */

@font-face {
  font-family: 'acumin-pro';
  src: url('../webfonts/acuminpro-light-webfont.woff2') format('woff2'),
       url('../webfonts/acuminpro-light-webfont.woff') format('woff');
  font-weight: 300;
  font-style: normal;

}

@font-face {
    font-family: 'acumin-pro';
    src: url('../webfonts/acuminpro-regular-webfont.woff2') format('woff2'),
         url('../webfonts/acuminpro-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
  font-family: 'acumin-pro';
  src: url('../webfonts/acuminpro-semibold-webfont.woff2') format('woff2'),
       url('../webfonts/acuminpro-semibold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}