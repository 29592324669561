// [1] Graph width must be double the width of the SVG
// [2] Repeat background image horizontally to create a seamless animation
// [3] Translation animation must be exactly the width of the SVG

.graph-wrapper {
  width: calc(100% + 1.25rem);
  border-radius: 0.25rem 0 0 0.25rem;


  @media screen and (min-width: 640px) {
    width: calc(640px + ( ( 100vw - 640px ) / 2));
  }

  @media screen and (min-width: 768px) {
    width: calc(768px + ( ( 100vw - 768px ) / 2) + 1.25rem);
  }

  @media screen and (min-width: 1024px) {
    width: 50vw;
    transform: translateX(-1.25rem);
  }

  @media screen and (min-width: 1536px) {
    width: calc(100% + 1.25rem);
    border-radius: 0.25rem;
  }

  .graph {
    width: 1600px; // [1]
    background-image: url('../images/graph.svg'); // [1]
    background-repeat: repeat-x; // [2]
    background-position-y: center;
    animation: graph 10s linear infinite;
  }
}

@keyframes graph {
  0% {
    transform: translateX(0)
  }
  100% {
    transform: translateX(-800px) // [3]
  }
}
