@keyframes moving-circles {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(50px, 50px) rotate(359deg);
  }
}
@keyframes blinking-caret {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
    stroke-dashoffset: (0.66 * $spinnerSize);
  }
  50% {
    transform: rotate(720deg);
    stroke-dashoffset: (3.14 * $spinnerSize);
  }
  100% {
    transform: rotate(1080deg);
    stroke-dashoffset: (0.66 * $spinnerSize);
  }
}

.swup-transition-page-out {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: transform 0.8s cubic-bezier(1,0,.73,.86), opacity 1s 0.2s cubic-bezier(1,0,.73,.86);
  transform: translateY(-100%);
  opacity: 0;

  html.swup-enabled & {
    // z-index: -1;
  }

  html.is-animating & {
    transition: opacity 0.3s ease;
    transform: translateY(0);
    opacity: 1;
  }
}

